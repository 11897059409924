import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
const addressDataList = [
  'Ramo Transz kft.',
  '1116 Budapest, XI. Kisújszállás, 5',
  'Adószám: 26392695-2-43'
];

const socialBtnList = [
  {
    icon: 'fa6-brands:facebook-f',
    href: 'https://www.facebook.com/profile.php?id=100063550389355',
  },
];

const today = new Date();

const formattedDate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');

const actualDate = today.getFullYear();

  
export default function Footer() {
  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{ backgroundImage: 'url(/images/footer_bg.jpeg)' }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img src="/images/logo-ramo-transz.png" alt="Logo" />
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>

                <div className="cs_footer_item">
                <h2 className="cs_widget_title pt-4">Elérhetőség</h2>
                <div className="cs_newsletter cs_style_1">
                   <a href="mailto:ramotransz@gmail.com">ramotransz@gmail.com</a> 
                    <br />
                  <a href="tel:+36209444759">+36 20 944 4759</a>
                </div>
              </div>
              </div>
            </div>
           
            
          </div>
        </div>
      </div>
      <div className="container text-center">
        <div className="cs_bottom_footer">
          <div className="cs_bottom_footer_left">
            <div className="cs_social_btns cs_style_1">
              {socialBtnList.map((item, index) => (
                <Link to={item.href} className="cs_center" key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div>
          </div>
          <div className="cs_copyright">Copyright © {actualDate} <br/>
           Webfejlesztés és Design  <a href="http://webmoon.hu" target="_blank" rel="noopener noreferrer"> 🌙 WebMoon.hu</a> </div>
          <div className="cs_bottom_footer_right">
            <ul className="cs_footer_links cs_mp0">
            {formattedDate}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
