import React from 'react';
import Hero from '../Hero';
import Spacing from '../Spacing';
import FunFact from '../FunFact';
import About from '../About';
import WhyChose from '../WhyChose';
import Service from '../Service';
import Portfolio from '../Portfolio';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
import Cta from '../Cta';
import { pageTitle } from '../../helpers/PageTitle';
import {Helmet} from "react-helmet";

const funfactData = [
  { title: 'év munkatapasztalat', number: '31+' },
  { title: 'Elégedett ügyfél', number: '30k+' },
  { title: 'féle konténer', number: '3' },
  { title: 'elszálított törmelék', number: '37T' },
];
const whyChoseFeatureData = [
  {
    title: 'Profi és pontos csapatmunka',
    content:
      'Csapatunk igyekszik a legpontosabban és legprofibban elszállítani törmelékét a lehető legrövidebb határidőn belül.',
  },
  {
    title: 'Megfelelő árak',
    content:
      'A nagy inflációhoz képest igyekszünk megfelelő árakon dolgozni, hogy mindenkihez eljuthassunk.',
  },
  {
    title: 'Vonzáskörzetünk',
    content:
      'Budapest - Dél-Buda - Albertfalva',
  },
];
const serviceListData = [
  {
    title: 'Vegyes hulladék elszállítás',
    subtitle:
      'Egyéb vegyes anyagok, mint gipszkarton, lom, papír, és műanyag elszállítását 4 m³-es konténerben(50 000 ft), 6 m³-es konténerben(70 000 ft), illetve 8 m³-es konténerben (85 000 ft) kiszállítással / házhozszállítással vállaljuk. Pontos ár-ajánlat érdekében keressen minket bizalommal telefonon (+36209444759).',
    imgUrl: '/images/usPic.png',
    href: `tel:+36209444759`,
  },
  {
    title: 'Építési hulladék elszállítás',
    subtitle:
      'Beton, cserép, téglatörmelék, vakolat, föld, csempe, valamint ezek keverékének elszállítását 4 m³-es konténerben(35 000 ft), 6 m³-es konténerben(50 000 ft), illetve 8 m³-es (65 000 ft) konténerben kiszállítással / házhozszállítással vállaljuk. Pontos ár-ajánlat érdekében keressen minket bizalommal telefonon (+36209444759).',
    imgUrl: '/images/szolg2.png',
    href: `tel:+36209444759`,
  },
  {
    title: 'Vállalunk házhoszállítást',
    subtitle:
      'Vállaljuk homok, sóder, kavics, termőföld, múrva és bazalt  elszállítását 4 m³-es konténerben, 6 m³-es konténerben, illetve 8 m³-es konténerben kiszállítással / házhozszállítással vállaljuk. Pontos ár-ajánlat érdekében keressen minket bizalommal telefonon (+36209444759).',
    imgUrl: '/images/szolg3.png',
    href: `tel:+36209444759`,
  },
];
const portfolioData = [
  {
    href: './images/ref1.png',
    imgUrl: '/images/ref1.png',
    title: '',
  },
  {
    href: './images/ref2.png',
    imgUrl: '/images/ref2.png',
    title: '',
  },
  {
    href: './images/ref3.png',
    imgUrl: '/images/ref3.png',
    title: '',
  },
  {
    href: './images/ref4.png',
    imgUrl: '/images/ref4.png',
    title: '',
  },
  {
    href: './images/ref5.png',
    imgUrl: '/images/ref5.png',
    title: '',
  },
];

const faqData = [
  {
    title: '00. Fontos!!!',
    content:
      'Ha a konténert telken belülre szeretnék elhelyezni, rendelés előtt kérem mérjék le a kapú szélességét, illetve a megközelíthetőséget!',
  },
  {
    title: '01. Hogy tudom Önöket elérni és mikor?',
    content:
      'Ezen a telefonszámon: 36201233211 vagy info@pelda.hu vagy info@gmail.com. Telefonszámon és emailben is bármikor eltud minket érni.',
  },
  {
    title: '02. Miket vállalnak?',
    content:
      'Homok, sóder , kavics, termőföld, múrva , bazalt házhozszállítást, illetve vegeyes anyagok szállításást is (itt van különbözet)'
  },
  {
    title: '03. Számlaképesek?',
    content:
      'Igen!',
  },
  {
    title: '04. Hány féle méret van a konténereknél?',
    content:
      '4 - 6 - 8 köbméteres konténerekkel rendelkezünk.',
  },
  {
    title: '05. Mekkora a teherautó?',
    content:
      'A teherautó szélessége 2,5 méter magassága 3,1 méter!'
  },
  {
    title: '06. Merre dolgoznak,hol elérhetők?',
    content:
      'Jelenleg cégünk Budapest - Dél-Buda - Albertfalva vonzáskörzetében dolgozik.'
  },
];

export default function Home() {
  pageTitle('Főoldal');
  return (
    <>
      <Hero
        title={[
          'Budapesti sittes kontéren szállítás',
          '31+ év tapasztalat',
          '30 000 + elégedett ügyfél',
          'Maximum megbízhatóság',
        ]}
        subtitle="RamoTransz KFT - Konténeres szállítás Dél-Buda - Albertfalva"
        videoSrc=""
        bgUrl="/images/hero.png"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Tekintse meg fő szolgáltatásainkat"
            subTitle="Szolgáltatásaink"
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Tekintse meg fő szolgáltatásainkat"
            sectionSubTitle="Szolgáltatásaink"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <Spacing lg="125" md="70" />
      <About
        thumbnail="/images/ramtransz_kft.png"
        uperTitle="Kik vagyunk Mi"
        title="RamoTransz kft."
        subTitle="
        A Ramotransz KFT 1993 óta specializálódott a konténeres szállításra,
         4, 6 és 8 köbméteres konténerekkel. Szolgáltatásaik közé 
         tartozik a homok, sóder, kavics, termőföld, múrva és bazalt 
         házhozszállítása. Körzeteink közé tartozik: Dél-Buda,Albertfalva."
        featureList={[
          'Megbízható',
          'Pontos',
          'Számlaképes',
        ]}
        btnText="Érdekel!"
        btnUrl="/about"
      />
      <Spacing lg="185" md="75" />
      <WhyChose
        sectionTitle="Több mint 31+ éves tapasztalattal rendelkezünk"
        sectionSubTitle="Miért válasszon Minket"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/ramtransz_kft_2.png"
      />
      <Spacing lg="150" md="80" />
    
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Referencia képek" subTitle="Galéria" />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Konténerre lenne szüksége Dél-Budán vagy Albertfalván?"
            btnText="Igen,felhívom!"
            btnUrl=""
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Gyakran ismételt kérdések" subTitle="Kérdések" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="application">
          <Helmet>
            <title>RamoTransz KFT - Konténeres sittes szállítás - Dél-Buda - Albertfalva</title>
            <meta name="description" content="RamTrans - Konténeres sittes szállítás - Dél-Buda - Albertfalva" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
            <link rel="manifest" href="/site.webmanifest"></link>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff7425"></link>
            <meta name="keywords" content="konténer, konténeres, sitt, sittszállítás, törmelék, lomtalanítás, lom, rendelés, szállítás, kontener, szemét, hulladék, homok, sóder, kavics, föld, termőföld, budapest, budaörs, elszállítás, délbuda, albertfalva,budapest, konténeres szállítás" />       
          </Helmet>
      </div>
    </>
  );
}
